import request from '@/utils/request';

//列表
export function ReportOilTypeCheckUp(params) {
  return request({
    url: '/Report_oil_type_check_up/data',
    method: 'get',
    params
  });
}

//导出
export function ReportOilTypeCheckUpExport(params) {
  return request({
    url: '/Report_oil_type_check_up/export',
    method: 'get',
    responseType: 'blob',
    params
  });
}

//添加
export function ReportOilTypeCheckUpAddForm(params) {
  return request({
    url: '/Report_oil_type_check_up/add_form',
    method: 'get',
    params
  });
}

// //添加照片
// export function ReportOilTypeCheckUpImgAdd(params) {
//   return request({
//     url: '/Report_oil_type_check_up/img_add',
//     method: 'get',
//     params
//   });
// }
