<template>
  <page-header :title="title" v-model="searchParams" :breadcrumbs="pathMatch">
    <template v-slot:searchbar>
      <div style="width: 100%; display: flex; justify-content: space-between;">
        <div>
          <el-button
            type="primary"
            icon="el-icon-plus"
            size="mini"
            @click="handleAddVisible"
            class="export-btn"
            >新增</el-button
          >
        </div>
        <el-form :inline="true" size="mini">
          <AreaField
            v-model="searchParams.area_code"
            @change="getStationList"
          />
          <el-form-item>
            <el-select
              v-model="searchParams.site_id"
              filterable
              :clearable="true"
              placeholder="加油站(可输入)"
            >
              <el-option
                v-for="item in gasData"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="searchParams.oil_name"
              filterable
              clearable
              placeholder="油品"
              style="width: 100%"
            >
              <el-option
                v-for="item in typeData"
                :key="item.value"
                :value="item.value"
                :label="item.type"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              v-model="date"
              type="daterange"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              placeholder="请选择"
              clearable
              @change="getTime"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              @click="handleQuery"
              >查询</el-button
            >
            <el-button
              type="primary"
              icon="el-icon-arrow-up"
              size="mini"
              @click="handleExport"
              >导出</el-button
            >
            <!-- <el-button
              type="primary"
              icon="el-icon-plus"
              size="mini"
              @click="actionEvent(form, 'add')"
              >新增</el-button
            > -->
          </el-form-item>
        </el-form>
      </div>
      <!-- 新增抽检记录 -->
      <el-dialog
        :title="isAdded ? '新增抽检记录' : '查看抽检记录'"
        :visible.sync="visible"
        width="900px"
        :close-on-click-modal="false"
      >
        <el-form
          ref="form"
          :rules="rules"
          :model="form"
          class="form-wrap"
          size="large"
          label-width="120px"
          label-position="right"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="加油站名称" :prop="isAdded ? 'site_id' : ''">
                <el-select
                  v-model="form.site_id"
                  filterable
                  placeholder="请选择"
                  style="width: 100%"
                  v-if="isAdded"
                >
                  <el-option
                    v-for="item in gasData"
                    :key="item.id"
                    :value="item.id"
                    :label="item.name"
                  />
                </el-select>
                <el-input
                  v-else
                  v-model="form.site_name"
                  placeholder="请输入"
                  :disabled="true"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="油罐号" :prop="isAdded ? 'tank_num' : ''">
                <el-input
                  v-model="form.tank_num"
                  :disabled="!isAdded"
                  placeholder="请选择"
                  style="width: 100%"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item
                label="来源炼厂"
                :prop="isAdded ? 'refinery_name' : ''"
              >
                <el-input
                  v-model="form.refinery_name"
                  :disabled="!isAdded"
                  placeholder="请选择"
                  style="width: 100%"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="发货油库" :prop="isAdded ? 'oil_depot' : ''">
                <el-input
                  v-model="form.oil_depot"
                  placeholder="请输入"
                  :disabled="!isAdded"
                  style="width: 100%"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="油品" :prop="isAdded ? 'oil_name' : ''">
                <el-select
                  v-model="form.oil_name"
                  filterable
                  :disabled="!isAdded"
                  :clearable="true"
                  placeholder="请输入"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in typeData"
                    :key="item.value"
                    :value="item.value"
                    :label="item.type"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="密度（kg/m³）"
                :prop="isAdded ? 'density' : ''"
              >
                <el-input
                  :type="isAdded ? 'number' : 'text'"
                  min="0"
                  step="0.1"
                  v-model="form.density"
                  placeholder="请输入"
                  :disabled="!isAdded"
                  style="width: 100%"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item
                label="进油量（L）"
                :prop="isAdded ? 'oil_sum_liter' : ''"
              >
                <el-input
                  :type="isAdded ? 'number' : 'text'"
                  min="0"
                  step="0.1"
                  v-model="form.oil_sum_liter"
                  placeholder="请输入"
                  :disabled="!isAdded"
                  style="width: 100%"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="运输车牌号"
                :prop="isAdded ? 'driver_license' : ''"
              >
                <el-input
                  v-model="form.driver_license"
                  placeholder="请输入"
                  :disabled="!isAdded"
                  style="width: 100%"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item
                label="司机电话"
                :prop="isAdded ? 'driver_phone' : ''"
              >
                <el-input
                  v-model="form.driver_phone"
                  placeholder="请输入"
                  :disabled="!isAdded"
                  style="width: 100%"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="司机姓名"
                :prop="isAdded ? 'driver_name' : ''"
              >
                <el-input
                  v-model="form.driver_name"
                  placeholder="请输入"
                  :disabled="!isAdded"
                  style="width: 100%"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="时间" :prop="isAdded ? 'complete_time' : ''">
                <el-date-picker
                  v-model="form.complete_time"
                  type="date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  placeholder="请选择"
                  clearable
                  style="width: 100%"
                  :disabled="!isAdded"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="上传图片">
                <span
                  v-if="fileList.length == 0 && !isAdded"
                  style="color: #999"
                  >没有上传图片！</span
                >
                <el-upload
                  :class="isAdded ? '' : 'image_url'"
                  accept=".jpg, .jpeg, .png, .gif"
                  ref="upload"
                  multiple
                  list-type="picture-card"
                  :limit="fileLimit"
                  action="/api/info/Report_oil_type_check_up/img_add"
                  :on-change="handleChange"
                  :on-remove="handleRemove"
                  :before-remove="handleBeforeRemove"
                  :on-exceed="handleExceed"
                  :on-preview="handlePictureCardPreview"
                  :file-list="fileList"
                  :http-request="uploadFile"
                  :auto-upload="false"
                  :disabled="!isAdded"
                >
                  <i slot="default" class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item style="text-align: center"></el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" v-if="isAdded">
          <el-button type="native-type" size="mini" @click="handleCancel('add')"
            >取 消</el-button
          >
          <el-button
            type="primary"
            size="mini"
            :disabled="ifDisabled"
            @click="submitUpload"
            >确 定</el-button
          >
          <!-- @click="handleAdd" -->
        </div>
      </el-dialog>

      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt />
      </el-dialog>
    </template>
    <default-table
      :columns="columns"
      :dataSource="data"
      :pagination="pagination"
      @currentChange="handleCurrentChange"
      :loading="tableLoading"
    >
      <template #index="{ scope }">{{ scope.$index + 1 }}</template>

      <template #actions="{ scope }">
        <el-button type="primary" size="small" @click="handleView(scope.row)"
          >查看</el-button
        >
      </template>
    </default-table>
  </page-header>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import DefaultTable from '@/components/DefaultTable/new';
import {
  ReportOilTypeCheckUp,
  ReportOilTypeCheckUpAddForm,
  ReportOilTypeCheckUpExport
} from '@/api/oil_detection';

import mixins from '@/mixins';

import { columns, rules } from './columns/column';
import axios from 'axios';

export default {
  components: {
    PageHeader,
    DefaultTable
  },
  data() {
    const _type = [
      { type: '92#', value: '92', type_value: '92#' },
      { type: '95#', value: '95', type_value: '95#' },
      { type: '98#', value: '98', type_value: '98#' },
      { type: '0#', value: '0', type_value: '0#' },
      { type: '-10#', value: '-10', type_value: '-10#' }
    ];
    return {
      typeData: _type,
      tableLoading: true,
      ifDisabled: false,
      searchParams: {},
      columns,
      data: [],
      date: '',
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10
      },
      tankerList: [], //加油机列表
      gunList: [], // 加油枪列表
      isAdded: false,
      visible: false,
      form: {},
      rules,

      fileLimit: 10,
      fileList: [],
      dialogImageUrl: '',
      dialogVisible: false
    };
  },
  mixins: [mixins],
  created() {
    this.pathMatch = this.$router.history.current.matched;
    this.handleQuery({
      current: 1,
      pageSize: 10
    });
  },
  methods: {
    handleQuery({ current, pageSize }) {
      this.pagination.current = current || 1;
      const params = {
        page: current || 1,
        limit: pageSize || 10,
        ...this.searchParams
      };
      ReportOilTypeCheckUp(params).then(res => {
        if (res.code === 0) {
          this.data = res.data;
          this.pagination = {
            ...this.pagination,
            total: res.count
          };
          this.tableLoading = false;
        }
      });
    },
    handleCurrentChange(current) {
      this.pagination.current = current;
      this.handleQuery({
        current,
        pageSize: 10
      });
    },
    handleExport() {
      const params = {
        ...this.searchParams
      };
      ReportOilTypeCheckUpExport(params)
        .then(res => {
          this.$ExportFile(res, this.title);
        })
        .catch(err => {
          this.$message({
            message: err,
            type: 'error'
          });
        });
    },
    handleCancel() {
      this.visible = false;
      this.tankerList = [];
      this.$refs['form'].resetFields();
    },
    handleAddVisible() {
      this.visible = true;
      this.isAdded = true;
      this.fileList = [];
      this.form = {};
      if (this.$refs['form'] !== undefined) {
        this.$refs['form'].clearValidate();
      }
    },
    actionEvent(row, type) {
      switch (type) {
        case 'add':
          this.visible = true;
          this.isAdded = true;
          this.fileList = [];
          this.form = {};
          if (this.$refs['form'] != undefined) {
            this.$refs['form'].clearValidate();
          }
          break;
      }
    },
    handleView(data) {
      this.visible = true;
      this.isAdded = false;
      ReportOilTypeCheckUp({
        id: data.id
      }).then(res => {
        if (res.code == 0) {
          this.form = {
            ...res.data[0],
            quantity: data.quantity
          };

          this.fileList = [];
          const image_url = res.data[0].image_url;
          let imageList = [];
          if (image_url) {
            if (image_url.indexOf(',') > -1) {
              imageList = image_url.split(',');
            } else {
              imageList = [...image_url];
            }
          }
          imageList.forEach(item => {
            this.fileList.push({
              name: item.slice(item.lastIndexOf('/')),
              url: item
            });
          });
        }
      });
    },
    // 新增信息
    handleAdd() {
      console.log(this.form);
      this.ifDisabled = true;
      ReportOilTypeCheckUpAddForm(this.form).then(res => {
        if (res.code == 0) {
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'success'
          });
          this.handleQuery({
            current: this.pagination.current,
            pageSize: 10
          });
          var _this = this;
          setTimeout(function() {
            _this.ifDisabled = false;
            _this.visible = false;
            _this.tankerList = [];
            _this.$refs['form'].resetFields();
          }, 1000);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 上传到服务器
    submitUpload() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          if (this.fileList.length != 0) {
            const isLt100M = this.fileList.every(
              file => file.size / 1024 / 1024 < 10
            );
            if (!isLt100M) {
              this.$message.error('请检查，单张图片大小不能超过10MB!');
            } else {
              this.fileData = new FormData(); // new formData对象
              this.$refs.upload.submit(); // 提交调用uploadFile函数
              console.log(this.fileData.getAll('image[]'));
              console.log(this.fileList);
              axios
                .post(
                  '/api/info/Report_oil_type_check_up/img_add',
                  this.fileData
                )
                .then(response => {
                  if (response.data.code === 0) {
                    this.form.only_id = response.data.data;
                    this.handleAdd();
                  } else {
                    this.$message({
                      message: response.data.msg,
                      type: 'error'
                    });
                  }
                });
            }
          } else {
            this.form.only_id = '';
            this.handleAdd();
          }
        } else {
          return false;
        }
      });
    },
    //监控上传文件列表
    handleChange(file, fileList) {
      let existFile = fileList
        .slice(0, fileList.length - 1)
        .find(f => f.name === file.name);
      if (existFile) {
        this.$message.error('当前文件已经存在!');
        fileList.pop();
      }
      this.fileList = fileList;
      console.log(this.fileList);
    },
    //移除
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    handleBeforeRemove(file) {
      return this.$confirm(`确定移除图片 ${file.name} ？`);
    },
    // 选取文件超过数量提示
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 ${this.fileLimit} 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    uploadFile(file) {
      console.log(file);
      this.fileData.append('image[]', file.file); // append增加数据
    },
    getTime(e) {
      if (e) {
        this.searchParams.begin = e[0];
        this.searchParams.end = e[1];
      } else {
        this.searchParams.begin = '';
        this.searchParams.end = '';
      }
    }
  }
};
</script>
<style>
.image_url .el-upload--picture-card {
  display: none;
}
</style>
