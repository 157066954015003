export const columns = [
  {
    title: '序号',
    key: 'index',
    width: 64,
    align: 'center',
    scopedSlots: {
      customRender: 'index'
    }
  },
  {
    title: '加油站名称',
    key: 'site_name',
    width: 200,
    ellipsis: true
  },
  {
    title: '油罐号',
    key: 'tank_num',
    minWidth: 100
  },
  {
    title: '来源炼厂',
    key: 'refinery_name',
    align: 'center',
    minWidth: 100
  },
  {
    title: '发货油库',
    key: 'oil_depot',
    align: 'center'
  },
  {
    title: '油品',
    key: 'oil_name',
    align: 'center'
  },
  {
    title: '密度(kg/m³)',
    key: 'density',
    align: 'center',
    minWidth: 100
    // extraType: 'result'
  },
  {
    title: '进油量(L)',
    key: 'oil_sum_liter'
  },
  {
    title: '运输车牌号',
    key: 'driver_license',
    minWidth: 100
  },
  {
    title: '司机手机',
    key: 'driver_phone',
    minWidth: 120
  },
  {
    title: '卸油时间',
    key: 'complete_time',
    minWidth: 180
  },
  {
    title: '操作',
    fixed: 'right',
    key: 'actions',
    width: 80,
    scopedSlots: {
      customRender: 'actions'
    }
  }
];

export const rules = {
  site_id: [{ required: true, message: '请输入加油站名称' }],
  tank_num: [{ required: true, message: '请输入油罐号' }],
  refinery_name: [{ required: true, message: '请输入来源炼厂' }],
  oil_depot: [{ required: true, message: '请输入发货油库' }],
  oil_name: [{ required: true, message: '请输入油品' }],

  density: [
    { required: true, message: '请输入密度（kg/m³）' },
    {
      pattern: /^[0-9]{0,8}([.]{1}[0-9]{0,2}){0,1}$/,
      message: '格式不正确, 最多输入整数八位并且最多保留小数后二位'
    }
  ],
  oil_sum_liter: [
    { required: true, message: '请输入进油量（L）' },
    {
      pattern: /^[0-9]{0,8}([.]{1}[0-9]{0,2}){0,1}$/,
      message: '格式不正确, 最多输入整数八位并且最多保留小数后二位'
    }
  ],
  driver_license: [{ required: true, message: '请输入运输车牌号' }],
  driver_phone: [
    { required: true, message: '请输入司机电话' },
    {
      pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
      message: '格式不正确, 请输入正确的手机格式'
    }
  ],
  driver_name: [{ required: true, message: '请输入司机姓名' }],
  complete_time: [{ required: true, message: '请选择时间' }]
};
